<template>
  <div>
    <!--TITULOS  -->
    <div class="air__utils__heading"> <h5>Administración de Personal</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <!--TITULOS  -->
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>
    <!--BOTONERA  -->
    <!--BARRA DE BUSQUEDA  -->
    <searchingBar :components="['filter', 'sort', 'pages']"
          :sortByOptions="sortByOptions"
          @Fectch="FetchTable"
          @filterValue="filterValue = $event"
          @sortValue="tableObject.sortValue = $event"
          @pageSize="tableObject.pagination.pageSize = $event"
          @currentPageValue="tableObject.pagination.current = $event">
      <div class="row justify-content-start" slot="customFilters">
        <div class="col-sm-12 col-md-6 col-lg-3" >
          <globalPlantFilter @onChangeValue="FetchTable"  @filterValue="filterWorkCenter = $event"/>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3" >
          <b-form-group id="puesto-group" label="Puesto" label-for="puesto">
            <b-form-select v-model="filterPosition" :options="operationPositionsCombo"
                           id="puesto" label="Puesto" @change="FetchTable()"
                           value-field="id" text-field="name">
              <template slot="first">
                <b-form-select-option value="" >Todas los Puestos</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3" >
          <b-form-group id="area-group" label="Área" label-for="area">
            <b-form-select v-model="filterArea" :options="operationAreasCombo"
                           id="area" label="Área" @change="FetchTable()"
                           value-field="id" text-field="name">
              <template slot="first">
                <b-form-select-option value="" >Todas las Áreas</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3" >
          <b-form-group id="status-group" label="Estatus" label-for="status">
            <b-form-select v-model="filterStatus" :options="statusCombo"
                           id="status" label="Estatus" @change="FetchTable()"
                           value-field="value" text-field="label">
              <template slot="first">
                <b-form-select-option value="" >Todas los Estatus</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
    </searchingBar>
    <!--BARRA DE BUSQUEDA  -->
    <!--TABLA-->
    <a-table bordered
        :columns="tableObject.columns"
        :data-source="tableObject.data"
        :pagination="tableObject.pagination"
        :loading="tableObject.loading"
        @change="FetchTable"
        :row-key="record => record.id">
      <template slot="details" slot-scope="data">
        <a-tabs default-active-key="1" >
          <a-tab-pane key="1" tab="Datos Laborales">
            <a-descriptions :title="`Clave: ${data.code}`" size="small" :column="{ sm: 1, md: 2, lg: 3, xl: 4}" >
              <a-descriptions-item>
                <strong class="text-primary" v-if="data.status">ACTIVO</strong>
                <strong class="text-danger" v-else>INACTIVO</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Planta">
                <strong class="text-real-blue">{{ data.workCenters.name }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Turno" v-if="data.operationShifts">
                <strong class="text-real-blue">
                  {{ data.operationShifts.name }}
                  ({{ moment(data.operationShifts.initial_time).format('HH:mm') }} -
                  {{ moment(data.operationShifts.final_time).format('HH:mm') }} )
                </strong>
              </a-descriptions-item>
              <a-descriptions-item label="Puesto">
                <strong> {{ data.operationPositions.name }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Área">
                <strong> {{ data.operationAreas.name }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Fecha de ingreso a la planta" v-if="data.registered_at">
                <strong> {{ moment(data.registered_at).format('DD/MM/YYYY') }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Costo x hora">
                <strong> {{new Intl.NumberFormat("en-US", {style: "currency", currency: "MXN"}).format(data.cost_hour)  }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Compañia contratista" v-if="data.operationCompanies">
                <strong> {{ data.operationCompanies.name }}</strong>
              </a-descriptions-item>
            </a-descriptions>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Datos Personales" force-render>
            <a-descriptions :title="`Clave: ${data.code}`" size="small" :column="{ sm: 1, md: 2, lg: 3, xl: 4}" >
              <a-descriptions-item label="Teléfono">
                <strong>{{ data.phone }}</strong>
              </a-descriptions-item>
              <a-descriptions-item label="Dirección">
                {{ data.address }}
              </a-descriptions-item>
            </a-descriptions>
          </a-tab-pane>
        </a-tabs>
      </template>
      <template slot="action" slot-scope="data">
        <b-button  variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle de Personal" ref="detailModal" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="formRules" ref="personalForm"
                      layout="vertical" :wrapper-col="{ span: 24}" v-show="!spinnerLoad">
          <!--TEXT - Clave de Empleado-->
          <a-form-model-item label="Clave de Empleado" ref="code"  prop="code" v-if="resourceObj.form.id">
              <a-input v-model.trim="resourceObj.form.code" placeholder="Clave de Empleado" size="large"/>
          </a-form-model-item>
          <!--TEXT -Clave de Empleado -->
          <!--TEXT - Nombre del Empleado-->
          <a-form-model-item label="Nombre del Empleado" ref="name"  prop="name" >
              <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre del Empleado" size="large"/>
          </a-form-model-item>
          <!--TEXT -Nombre del Empleado -->
          <hr class="bg-primary">
          <!--SELECT -Planta -->
          <a-form-model-item label="Planta" ref="workCenters" prop="workCenters" >
            <globalPlantSelect v-model="resourceObj.form.workCenters" @change="FetchShiftCombo"/>
          </a-form-model-item>
          <!--SELECT -Planta -->
          <!--SELECT -Turno -->
          <a-form-model-item label="Turno" ref="operationShifts" prop="operationShifts" v-if="resourceObj.form.workCenters">
              <a-select v-model="resourceObj.form.operationShifts"
                          placeholder="Seleccionar Turno" allowClear
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in operationShiftsCombo" :key="option.id" :value="option.id" >
                    {{ option.name }}
                    ({{ moment(option.initial_time).format('HH:mm') }} -
                    {{ moment(option.final_time).format('HH:mm') }} )
                  </a-select-option>
              </a-select>
          </a-form-model-item>
          <!--SELECT -Turno -->
          <hr class="bg-primary">
          <!--SELECT -Puesto -->
          <a-form-model-item label="Puesto" ref="operationPositions" prop="operationPositions" >
              <a-select v-model="resourceObj.form.operationPositions"
                          placeholder="Seleccionar Puesto"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in operationPositionsCombo" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
          </a-form-model-item>
          <!--SELECT -Puesto -->
          <!--SELECT -Área -->
          <a-form-model-item label="Área" ref="operationAreas" prop="operationAreas" >
              <a-select v-model="resourceObj.form.operationAreas"
                          placeholder="Seleccionar Área"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in operationAreasCombo" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
          </a-form-model-item>
          <!--SELECT -Área -->
          <!--SELECT -Compañia contratista -->
          <a-form-model-item label="Compañía Contratista" ref="operationCompanies" prop="operationCompanies" >
              <a-select v-model="resourceObj.form.operationCompanies"
                          placeholder="Seleccionar Compañía" allowClear
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in operationCompaniesCombo" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
              </a-select>
          </a-form-model-item>
          <!--SELECT -Compañia contratista -->

          <!--costo/Tel/Desde/Activo? -->
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 24 }" :md="{ span:6}">
              <!--NUMBER INPUT - Costo x hora-->
              <a-form-model-item label="Costo x hora" ref="cost_hour"  prop="cost_hour" >
                <a-input-number v-model="resourceObj.form.cost_hour" :min="0" :step="1" size="large" style="width: 100%"/>
              </a-form-model-item>
              <!--NUMBER INPUT -Costo x hora -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:6}">
              <!--TEXT - Teléfono-->
              <a-form-model-item label="Teléfono" ref="phone"  prop="phone" >
                <a-input v-model.trim="resourceObj.form.phone" placeholder="Teléfono" size="large"/>
              </a-form-model-item>
              <!--TEXT -Teléfono -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:8}">
              <!--DATEPICKER - Desde-->
              <a-form-model-item  label="Desde" ref="registered_at" prop="registered_at" >
                  <a-date-picker  v-model="resourceObj.form.registered_at" placeholder="Desde"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
              </a-form-model-item>
              <!--DATEPICKER - Desde -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:4}">
              <!--TEXT - Activo?-->
              <a-form-model-item label="Activo?" ref="status"  prop="status" >
                <a-switch v-model.trim="resourceObj.form.status" />
              </a-form-model-item>
              <!--TEXT -Activo? -->
            </a-col>
          </a-row>
          <!--costo/Tel/Desde/Activo? -->
          <!--TEXT-AREA - Dirección-->
          <a-form-model-item label="Dirección" ref="address"  prop="address" >
              <a-textarea v-model.trim="resourceObj.form.address" :auto-size="{ minRows: 2, maxRows: 7 }"/>
          </a-form-model-item>
          <!--TEXT-AREA -Dirección -->

          <div class="row justify-content-end">
            <b-button-group>
              <b-button  variant="primary" @click="Submit" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
              <b-button v-if="resourceObj.form.id"  variant="danger" @click="AskDelete" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="trash"/> Eliminar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="trash"/>
                </div>
              </b-button>
            </b-button-group>
          </div>
        </a-form-model>
        <my-spinner :load="spinnerLoad"/>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'adminPersonal',
  mixins: [fractalMixin],
  data() {
    return {
      // Constantes
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          name: 'operations',
        },
        {
          breadcrumbName: 'Control de Operaciones',
          name: 'operationControl',
        },
        {
          breadcrumbName: 'Control de Personal',
          name: 'personalControl',
        },
        {
          breadcrumbName: 'Administración de Personal',
          aIcon: '',
        },
      ],
      // Constantes
      // SearchBar
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre' },
        { value: 'cost', text: 'Costo x hora' },
        { value: 'time', text: 'Antiguedad' },
      ],
      filterValue: '',
      filterWorkCenter: '',
      filterPosition: '',
      filterArea: '',
      filterStatus: '',
      // SearchBar
      // Tabla
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'name',
            class: 'text-center',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'details' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
          },
        ],
      },
      // Tabla
      // Objeto principal del recurso
      resourceObj: {
        resourceType: 'operationEmployees',
        form: {
          id: '',
          code: '',
          name: '',
          phone: '',
          address: '',
          cost_hour: 0,
          registered_at: '',
          status: true,
          workCenters: undefined,
          operationPositions: undefined,
          operationAreas: undefined,
          operationCompanies: undefined,
          operationShifts: undefined,
        },
        relationships: ['workCenters', 'operationPositions', 'operationAreas', 'operationCompanies', 'operationShifts'],
      },
      // Reglas de Formulario
      formRules: {
        name: [{ required: true, message: 'El campo Nombre es obligatorio.', trigger: 'blur' },
          { min: 3, max: 400, message: 'El campo Nombre contener entre 3 y 400 caracteres.', trigger: 'blur' }],
        workCenters: [{ required: true, message: 'El campo Planta es obligatorio.', trigger: 'blur' }],
        operationShifts: [{ required: true, message: 'El campo Turno es obligatorio.', trigger: 'blur' }],
        operationPositions: [{ required: true, message: 'El campo Puesto es obligatorio.', trigger: 'blur' }],
        operationAreas: [{ required: true, message: 'El campo Área es obligatorio.', trigger: 'blur' }],
        // operationCompanies: [{ required: true, message: 'El campo Compañia Contratista es obligatorio.', trigger: 'blur' }],
        // cost_hour: [{ min: 0, message: 'El campo Costo x hora no puede ser menor que cero.', trigger: 'change' }],
        registered_at: [{ type: 'date', message: 'El campo Fecha de ingreso a la planta debe ser una fecha válida.', trigger: 'change' }],
      },
      // Combos de Formulario
      operationShiftsCombo: [],
      operationPositionsCombo: [],
      operationAreasCombo: [],
      operationCompaniesCombo: [],
      statusCombo: [{ value: 1, label: 'ACTIVO' }, { value: 0, label: 'INACTIVO' }],
      // Variables para el flujo del formulario
      spinnerLoad: false,
    }
  },
  methods: {
    // CRUD
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObject.pagination.current}` : `${this.tableObject.currentPage}`,
        'page[size]': `${this.tableObject.pagination.pageSize}`,
        include: 'workCenters,operationPositions,operationAreas,operationCompanies,operationShifts',
        'fields[workCenters]': 'name',
        'fields[operationPositions]': 'name',
        'fields[operationAreas]': 'name',
        'fields[operationCompanies]': 'name',
        'fields[operationShifts]': 'name,initial_time,final_time',
        sort: this.tableObject.sortValue,
      }
      if (this.filterValue) params['filter[global]'] = this.filterValue.trim()
      if (this.filterWorkCenter !== '') params['filter[workCenters]'] = this.filterWorkCenter
      if (this.filterPosition !== '') params['filter[operationPositions]'] = this.filterPosition
      if (this.filterArea !== '') params['filter[operationAreas]'] = this.filterArea
      if (this.filterStatus !== '') params['filter[status]'] = this.filterStatus
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObject, params)
    },
    async Submit() {
      this.$refs.personalForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async AskDelete() {
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback)
    },
    async FetchShiftCombo (workCenterValue) {
      this.operationShiftsCombo = await this.GetResource('operationShifts', {
        sortBy: 'name',
        'fields[operationShifts]': 'code,name,initial_time,final_time',
        'filter[workCenters]': workCenterValue,
      })
    },
    async FetchCombos() {
      this.operationPositionsCombo = await this.GetResource('operationPositions', {
        sortBy: 'name',
        'fields[operationPositions]': 'code,name',
      })

      this.operationAreasCombo = await this.GetResource('operationAreas', {
        sortBy: 'name',
        'fields[operationAreas]': 'code,name',
      })
      this.operationCompaniesCombo = await this.GetResource('operationCompanies', {
        sortBy: 'name',
        'fields[operationCompanies]': 'code,name',
      })
    },
    async OpenModal(data = {}) {
      this.$refs.detailModal.show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.ResetForm()
      } else {
        await this.FetchShiftCombo(data.workCenters.id.toString())
        // Update case
        this.resourceObj.form = {
          id: data.id.toString(),
          code: data.code,
          name: data.name,
          phone: data.phone,
          address: data.address,
          cost_hour: data.cost_hour,
          status: data.status,
          workCenters: data.workCenters.id.toString(),
          operationPositions: data.operationPositions.id.toString(),
          operationAreas: data.operationAreas.id.toString(),
          // Optional
          // registered_at: data.registered_at,
          // operationShifts: data.operationShifts.id.toString(),
          // operationCompanies: data.operationCompanies.id.toString(),
          registered_at: data.registered_at ? data.registered_at : '',
          operationShifts: data.operationShifts ? data.operationShifts.id.toString() : undefined,
          operationCompanies: data.operationCompanies ? data.operationCompanies.id.toString() : undefined,
        }
        // this.resourceObj.form.operationCompanies = data.operationCompanies ? data.operationCompanies.id.toString() : undefined
        // this.resourceObj.form.operationShifts = data.operationShifts ? data.operationShifts.id.toString() : undefined
        // this.resourceObj.form.registered_at = data.registered_at ? data.registered_at : ''
      }
    },
    CloseModal() {
      this.$refs.detailModal.hide()
      this.ResetForm()
    },
    ResetForm() {
      this.resourceObj.form = {
        id: '',
        code: '',
        name: '',
        phone: '',
        address: '',
        cost_hour: 0,
        registered_at: '',
        status: true,
        workCenters: undefined,
        operationPositions: undefined,
        operationAreas: undefined,
        operationCompanies: undefined,
        operationShifts: undefined,
      }
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    // CRUD
  },
  async mounted() {
    await this.FetchTable()
    await this.FetchCombos()
  },
}
</script>
<style scoped></style>
